import { array, string, object, boolean } from 'yup';

const employerContributionStatementFields = {
  statementFiles: array().length(1, 'Please upload one statement'),
  accountNumber: string()
    .nullable()
    .trim()
    .max('24', 'Please enter a shorter account number')
    .required('Please enter your account number'),
  hasOptedIntoSlc: boolean().isTrue(
    'Please click the checkbox to agree to the above statement',
  ),
};

const employerContributionConnectingLoansFields = {
  hasOptedIntoConnectingLoans: boolean().isTrue(
    'Please click the checkbox to agree to the above statement',
  ),
};

export const employerContributionStatementSchema = object().shape(
  employerContributionStatementFields,
);

export const employerContributionConnectingLoansSchema = object().shape(
  employerContributionConnectingLoansFields,
);
