import { useFormikContext, Formik } from 'formik';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  Button,
  FlexRow,
  Header,
  Text,
  TextInput,
  Checkbox,
} from '@summer/ui-components';

import SlcFileUpload from 'components/pages/employerContribution/shared/SlcFileUpload';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { fetchFileCollection } from 'redux/actions/fileCollections.actions';
import { createSlcUser } from 'redux/actions/slc.actions';
import { getEmployerContributionStatement } from 'redux/selectors/fileCollections.selectors';
import { employerContributionStatementSchema } from 'schemas/employerContribution';

const collectionType = 'studentLoanContribution';

const initialValues = {
  statementFiles: [],
  accountNumber: undefined,
  hasOptedIntoSlc: false,
};

const EmployerContributionStatementUpload = ({
  uploadedDocuments,
  isLoading,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFileCollection({ collection: collectionType }));
  }, [dispatch]);

  const handleSave = ({ accountNumber, statementFiles }) => {
    dispatch(
      createSlcUser({
        accountNumber,
        fileId: statementFiles[0].id,
      })(),
    );
  };

  const validate = values => {
    const valuesToCheck = {
      ...values,
      statementFiles: values.statementFiles.filter(file => !file?.error),
    };

    return employerContributionStatementSchema
      .validate(valuesToCheck, { abortEarly: false })
      .then(() => ({}))
      .catch(validationError => {
        return validationError.inner.reduce(
          (errorsToResolve, item) => ({
            ...errorsToResolve,
            [item.path]: item.message,
          }),
          {},
        );
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSave}
      validate={validate}
    >
      <EmployerContributionStatementUploadForm
        filesCollection={uploadedDocuments}
        isLoading={isLoading}
      />
    </Formik>
  );
};

const EmployerContributionStatementUploadForm = ({
  filesCollection,
  isLoading,
}) => {
  const formik = useFormikContext();
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    isSubmitting,
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = formik;

  return (
    <Container>
      <PageHeader h2 bold>
        Upload a recent student loan statement
      </PageHeader>
      <Text paragraph size="small">
        We’ll need some additional information to enroll you in your employer’s
        student loan contribution benefit. Please upload the most recent
        statement from your student loan servicer that shows the name of your
        student loan servicer and your account number.
      </Text>
      <CardContent>
        <ContentLeft>
          <DocumentRequirements />
        </ContentLeft>
        <ContentRight>
          <SlcFileUpload
            fileCollection={filesCollection}
            collection={collectionType}
            name="statementFiles"
            value={values.statementFiles}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </ContentRight>
      </CardContent>
      <Wrapper>
        <TextInput
          label="Account Number"
          name="accountNumber"
          touched={touched.accountNumber}
          isSubmitting={isSubmitting}
          error={errors.accountNumber}
          value={values.accountNumber}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Wrapper>
      <Wrapper>
        <Checkbox
          name="hasOptedIntoSlc"
          label={
            <Text paragraph>
              By providing your student loan information, you are attesting that
              you are providing the information for a loan that was taken out
              under your own name. Your employer may request additional
              verification, and you hereby authorize Summer to share information
              for this purpose.
            </Text>
          }
          onChange={handleChange}
          checked={values.hasOptedIntoSlc}
        />
      </Wrapper>
      <ButtonContainer>
        <Button
          width={150}
          isLoading={isLoading}
          onClick={handleSubmit}
          disabled={!isValid}
          fakeDisabled={!isValid}
        >
          Finish
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const DocumentRequirements = () => {
  return (
    <>
      <Text size="extraLarge" bold paragraph>
        Your document needs to show:
      </Text>
      <UnorderedList>
        <li>
          <Text paragraph color="dark">
            Your full name
          </Text>
        </li>
        <li>
          <Text paragraph color="dark">
            Your loan servicer’s name and payment address
          </Text>
        </li>
        <li>
          <Text paragraph color="dark">
            Your account number
          </Text>
        </li>
      </UnorderedList>

      <Text paragraph size="small">
        You should be able to download your statement by logging into your
        online account on your loan servicer’s website and looking for a tab
        that says Documents, Statements, or Inbox. There’s more information on
        specific loan servicers{' '}
        <Link
          href="https://summer.zendesk.com/hc/en-us/articles/32778393674515-Where-do-I-find-my-student-loan-statement"
          inheritColor
          underline
        >
          in our Help Center
        </Link>{' '}
      </Text>
    </>
  );
};

const Container = styled.div`
  padding: 16px 16px 0px 16px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const PageHeader = styled(Header)`
  margin-bottom: 18px;

  @media (max-width: ${MobileSize}) {
    text-align: center;
  }
`;

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 12px;
`;

const CardContent = styled(FlexRow)`
  display: flex;
  text-align: left;
  justify-content: center;

  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 26px;
  @media (min-width: ${MobileSize}) {
    flex-direction: row;
  }
`;

const ContentLeft = styled.div`
  align-self: stretch;

  margin-bottom: 24px;
  max-width: 340px;

  @media (min-width: ${MobileSize}) {
    margin-bottom: 0;
    margin-right: 50px;
  }
`;

const ContentRight = styled.div`
  max-width: 600px;
  width: 100%;

  @media (min-width: ${MobileSize}) {
    height: 290px;
    max-height: 290px;
    overflow: hidden;
  }
`;

const UnorderedList = styled.ul`
  margin-left: 20px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled(FlexRow)`
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const mapStateToProps = state => ({
  uploadedDocuments: getEmployerContributionStatement(state),
  isLoading: false,
});

export default connect(mapStateToProps)(EmployerContributionStatementUpload);
